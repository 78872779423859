import {
  ItemInterfaceResolvers,
  ItemResolvers,
  CollectionResolvers,
  FileResolvers,
  Resolvers,
  RichTextResolvers
} from "./types/resolvers-types";
import {
  ItemParent,
  FileParent,
  CollectionParent,
  RichTextParent,
  ItemInterfaceParent
} from "./parents";
import * as ModelUtils from "./models/modelUtils";
import { GraphQLResolveInfo } from "graphql";
import * as Search from "./search";

async function readMetadata(
  parent: ItemInterfaceParent,
  key: string
): Promise<string[]> {
  let metadata = null;
  if (parent.preloadedMetadata.has(key)) {
    metadata = parent.preloadedMetadata.get(key);
  } else {
    const json = await parent.apiResource.read();
    if (json == null) {
      return null;
    }
    metadata = json.metadata[key];
  }
  if (metadata == null) {
    return null;
  }
  return Array.isArray(metadata) ? metadata : [metadata];
}

async function readFirstMetadata(
  item: ItemInterfaceParent,
  key: string
): Promise<string | null> {
  const metadata = await readMetadata(item, key);
  if (metadata == null) {
    return null;
  }
  return metadata[0];
}

function richTextFromHTML(html: string) {
  return new RichTextParent(html);
}

export const ItemInterfaceResolver: ItemInterfaceResolvers = {
  __resolveType(item) {
    if (item instanceof ItemParent) {
      return "Item";
    } else if (item instanceof CollectionParent) {
      return "Collection";
    }
    throw new Error("Invalid item type");
  },
  id(item) {
    return item.identifier;
  },
  identifier(item) {
    return item.identifier;
  },
  url(item) {
    return `https://archive.org/details/${item.identifier}`;
  },
  title(item) {
    return readFirstMetadata(item, "title");
  },
  async descriptions(item, { first }) {
    return (await readMetadata(item, "description"))
      .slice(0, first)
      .map(richTextFromHTML);
  },
  async primary_description(item) {
    const html = await readFirstMetadata(item, "description");
    if (html == null) {
      return null;
    }
    return richTextFromHTML(html);
  },
  async primary_collection(item) {
    const collection = await readFirstMetadata(item, "collection");
    if (collection == null) {
      return null;
    }
    return CollectionParent.fromIdentifier(collection);
  },
  async collections(item) {
    const collections = await readMetadata(item, "collection");
    return collections.map(identifier => {
      return CollectionParent.fromIdentifier(identifier);
    });
  },
  uploader(item) {
    return readFirstMetadata(item, "uploader");
  },
  subjects(item) {
    return readMetadata(item, "subjects");
  },
  date() {
    return this._readFirstMetadata("date");
  },
  // Docs say this _cannot_ be repeatable, but it can.
  contributors(item) {
    return readMetadata(item, "contributors");
  },
  creators(item) {
    return readMetadata(item, "creators");
  },
  languages(item) {
    return readMetadata(item, "languages");
  },
  public_date(item) {
    return readFirstMetadata(item, "publicdate");
  },
  scan_date(item) {
    return readFirstMetadata(item, "scandate");
  },
  thumbnail_url(item) {
    return `https://archive.org/services/img/${item.identifier}`;
  },
  async files(item, { first }): Promise<FileParent[]> {
    const json = await item.apiResource.read();
    if (json == null) {
      return [];
    }

    return json.files.slice(0, first).map(fileResponse => {
      return new FileParent(fileResponse, item.identifier);
    });
  }
};

export const ItemResolver: ItemResolvers = { ...ItemInterfaceResolver };

export const CollectionResolver: CollectionResolvers = {
  ...ItemInterfaceResolver,
  items(item, { first = 50, page = 0 }, context, info: GraphQLResolveInfo) {
    return Search.searchItems(
      {
        filters: {
          collection: item.identifier
        },
        first,
        page
      },
      info
    );
  }
};

export const FileResolver: FileResolvers = {
  url(file, { cors }) {
    const path = cors ? "cors" : "download";
    return `https://archive.org/${path}/${file.itemIdentifier}/${file.json.name}`;
  },
  name(file) {
    return file.json.name;
  },
  md5(file) {
    return file.json.md5;
  }
};

const RichTextResolver: RichTextResolvers = {
  html(richText) {
    return richText.html;
  },
  text(richText) {
    // TODO: How would this work on a server?
    const node = document.createElement("span");
    node.innerHTML = richText.html;
    return node.innerText;
  }
};

const resolvers: Resolvers = {
  Query: {
    item: async (_, { identifier }) => {
      return ItemParent.fromIdentifier(identifier);
    },
    collection: async (_, { identifier }) => {
      return CollectionParent.fromIdentifier(identifier);
    },
    search: async (_, { query, first, page }, context, info) => {
      return Search.searchItems(
        {
          filters: {
            string: query
          },
          first,
          page
        },
        info
      );
    }
  },
  Item: ItemResolver,
  Collection: CollectionResolver,
  File: FileResolver,
  ItemInterface: ItemInterfaceResolver,
  RichText: RichTextResolver
};

export default resolvers;
