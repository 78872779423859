import { ItemInterfaceResolvers } from "./types/resolvers-types";
import { ItemParent, CollectionParent, ItemInterfaceParent } from "./parents";
import * as ModelUtils from "./models/modelUtils";
import { GraphQLResolveInfo } from "graphql";

type SearchQuery = {
  filters: {
    collection?: string;
    string?: string;
  };
  first: number;
  page: number;
};

class UnreachableCaseError extends Error {
  constructor(val: never) {
    super(`Unreachable case: ${val}`);
  }
}

function arrayUnique<V>(arr: V[]): V[] {
  return Array.from(new Set(arr));
}

function getSearchFieldFromGraphQLField(
  gqlFiledName: keyof ItemInterfaceResolvers | null | undefined
) {
  // In an ideal world this could just be a Map, but this exhaustive switch
  // gives us type safety so we will get a type error if we add a new field and
  // don't handle it here.
  switch (gqlFiledName) {
    // keyof allows null/undefined
    case null:
    case undefined:
      return null;
    case "title":
      return "title";
    case "__resolveType":
    // @ts-ignore Not sure why this is missing
    case "__typename":
      return "mediatype";
    case "identifier":
    case "url":
    case "id":
    case "thumbnail_url":
      return "identifier";
    case "descriptions":
    case "primary_description":
      return "description";
    case "collections":
    case "primary_collection":
      return "collection";
    case "uploader":
    case "subjects":
    case "date":
    case "contributors":
    case "creators":
    case "languages":
    case "public_date":
    case "scan_date":
    case "files":
      // These values cannot be derived from the search API.
      return null;
    default:
      throw new UnreachableCaseError(gqlFiledName);
  }
}

function getSearchFieldFromInfo(info: GraphQLResolveInfo) {
  const { selections } = info.fieldNodes[0].selectionSet;

  const graphQLFields = selections.map(selection => {
    if (selection.kind === "Field") {
      return selection.name.value as keyof ItemInterfaceResolvers;
    }
    return null;
  });

  return graphQLFields.map(getSearchFieldFromGraphQLField).filter(Boolean);
}

export async function searchItems(
  query: SearchQuery,
  info: GraphQLResolveInfo
): Promise<ItemInterfaceParent[]> {
  const searchFields = getSearchFieldFromInfo(info);

  // Always get the identifier and mediatype
  const fields = arrayUnique(["identifier", "mediatype", ...searchFields]);

  const filters = [];
  if (query.filters.collection != null) {
    filters.push(`collection:${query.filters.collection}`);
  }
  if (query.filters.string != null) {
    // TODO: We could try to sanitize this?
    filters.push(`string`);
  }

  // If we ever run on the server, we would want to conditionally use fetch
  const response = await ModelUtils.search({
    q: filters.join(","),
    rows: String(query.first),
    page: String(query.page),
    fl: fields,
    sort: "downloads desc"
  });
  return response.response.docs.map(doc => {
    if (doc.mediatype === "collection") {
      return new CollectionParent(doc.identifier, doc);
    }
    return new ItemParent(doc.identifier, doc);
  });
}
