import { SearchResponse } from "../types/search-api";

function isValidIdentifier(identifier: string): boolean {
  return /[a-zA-Z0-9][a-zA-Z0-9.-_]{0,99}/.test(identifier);
}

export function assertIdentifierIsValid(identifier: string): void {
  if (!isValidIdentifier(identifier)) {
    throw new Error(`${identifier} is not a vaid Internet Archive identifier`);
  }
}

export function repeatable<T>(value: T | T[]): T[] {
  if (value == null) {
    return [];
  }
  return Array.isArray(value) ? value : [value];
}

let id = 0;
function uniqueId() {
  return id++;
}

export function fetchJsonP<TResponse extends {}>(
  urlObj: URL
): Promise<TResponse> {
  const callbackName = `callback_${uniqueId()}`;
  const script = document.createElement("script");

  return new Promise<TResponse>((resolve, reject) => {
    const callback = (response: TResponse) => {
      resolve(response);
    };

    // @ts-ignore
    window[callbackName] = callback;

    script.addEventListener("error", e => {
      reject(e);
    });
    urlObj.searchParams.set("callback", callbackName);
    script.src = urlObj.toString();
    document.body.appendChild(script);
  }).finally(() => {
    // Cleanup
    // @ts-ignore
    window[callbackName] = null;
    document.body.removeChild(script);
  });
}

type MaybeMultiObj = {
  [key: string]: string | string[];
};

function addValue(
  obj: MaybeMultiObj,
  key: string,
  value: string
): MaybeMultiObj {
  if (!(key in obj)) {
    return { ...obj, key: value };
  }
  const oldValue = obj[key];
  const oldValues = Array.isArray(oldValue) ? oldValue : [oldValue];
  return { ...obj, key: [...oldValues, value] };
}

export async function search(params: MaybeMultiObj): Promise<SearchResponse> {
  // Always get mediatype, since we need it to know if we have a collection.
  const paramValues = addValue(params, "fl", "mediatype");

  const urlObj = new URL("https://archive.org/advancedsearch.php");
  urlObj.searchParams.set("output", "json");
  for (const [key, value] of Object.entries(paramValues)) {
    if (Array.isArray(value)) {
      // Archive.org expects the syntax: `key[]=value1,value2` instead of
      // `key=value1&key=value2`, which is what SearchParams does.
      urlObj.searchParams.set(`${key}[]`, value.join(","));
    } else {
      urlObj.searchParams.set(key, value);
    }
  }
  return fetchJsonP(urlObj);
}
