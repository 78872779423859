type QueryData = {
  url: string;
  start: number;
  end: number;
};

// The codegen.yml format for referencing modules does not work well for default
// exports, so we export it as a named export as well.
export class GraphQLContext {
  queries: QueryData[];
  constructor() {
    this.queries = [];
  }

  addQueryData(queryData: QueryData) {
    this.queries.push(queryData);
  }
}

export default GraphQLContext;
