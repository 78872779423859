import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useParams } from "react-router-dom";

export default function CollectionPage() {
  let { identifier } = useParams();
  const { loading, error, data } = useQuery(
    gql`
      query Query($identifier: String!) {
        collection(identifier: $identifier) {
          id
          title
          primary_description {
            text
          }
          items(first: 20) {
            id
            title
            thumbnail_url
          }
        }
      }
    `,
    { variables: { identifier } }
  );

  if (loading) return <p>Loading...</p>;
  if (error) {
    return <p>Error :(</p>;
  }

  const { collection } = data;

  return (
    <div>
      <h1>{collection.title}</h1>
      <p>{collection.primary_description.text}</p>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {collection.items.map(({ title, id, thumbnail_url }) => {
          return (
            <div key={id} style={{ border: "1px solid grey", width: 200 }}>
              <div>{title}</div>
              <img src={thumbnail_url} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
