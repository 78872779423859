import schema from "./schema";
import React, { useCallback } from "react";
import gql from "graphql-tag";
import GraphiQL from "graphiql";
import { useApolloClient } from "@apollo/react-hooks";

import "graphiql/graphiql.css";

/*

async function graphQLFetcher(graphQLParams) {
  const response = await fetch("https://archive.org/graphql", {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(graphQLParams)
  });
  return response.json();
}
*/

const defaultQuery = ` query Query {
    collection(identifier: "winampskins") {
      id
      title
      primary_description {
        text
      }
      items(first: 2) {
        id
        title
        files(first: 2) {
          name
        }
      }
      primary_collection {
        title
      }
    }
  }`;

export default function() {
  const client = useApolloClient();

  const graphQLFetcher = useCallback(async graphQLParams => {
    const response = await client.query({
      query: gql(graphQLParams.query)
    });
    return response.data;
  });

  return (
    <GraphiQL
      fetcher={graphQLFetcher}
      schema={schema}
      query={defaultQuery}
      docExplorerOpen={true}
    />
  );
}
