import { makeExecutableSchema } from "graphql-tools";
import resolvers from "./resolvers";
import fs from "fs";
// Parcel's .graphql imports are broken on this beta. So we work around it using
// their understanding of readFileSync.
const typeDefs = fs.readFileSync(__dirname + "/schema.graphql", "utf8");

const schema = makeExecutableSchema({
  typeDefs,
  resolvers
});

export default schema;
