import client from "./client";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import GraphiQL from "./GraphiQL";
import CollectionPage from "./CollectionPage";
import { ApolloProvider } from "@apollo/react-hooks";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./css/app.css";

/*
if ("serviceWorker" in navigator) {
  window.addEventListener("load", async () => {
    try {
      await navigator.serviceWorker.register("./sw.js");
    } catch (err) {
      console.log("ServiceWorker registration failed: ", err);
    }
  });
}
*/

const App = () => {
  return (
    <Router>
      <ApolloProvider client={client}>
        <Suspense fallback={<p>Loading...</p>}>
          <Switch>
            <Route path="/">
              <GraphiQL client={client} />
            </Route>
            <Route path="/graphql">
              <GraphiQL client={client} />
            </Route>
            <Route path="/details/:identifier">
              <CollectionPage />
            </Route>
          </Switch>
        </Suspense>
      </ApolloProvider>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));
