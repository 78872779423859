import { SchemaLink } from "apollo-link-schema";
import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import schema from "./schema";
import GraphQlContext from "./GraphQLContext";

const client = new ApolloClient({
  link: new SchemaLink({ schema, getContext: () => new GraphQlContext() }),
  cache: new InMemoryCache()
});

export default client;
