import { MetadataAPIResponse, ResponseMetadata } from "./types/metadata-api";
import { createLazyResource, LazyResource } from "./lazyResource";
import * as ModelUtils from "./models/modelUtils";
import { ResponseFile } from "./types/metadata-api";

async function fetchItem(
  identifier: string
): Promise<MetadataAPIResponse | null> {
  const response = await fetch(`https://archive.org/metadata/${identifier}`);
  if (response == null) {
    return null;
  }
  const json = await response.json();
  if (json.metadata == null) {
    // Ideally archive.org would return 400 in this case, but it just returns `{}` with status 200.
    return null;
  }
  return json;
}

export class ItemInterfaceParent {
  identifier: string;
  preloadedMetadata: Map<string, string | string[]>;
  apiResource: LazyResource<MetadataAPIResponse>;

  constructor(identifier: string, metadata: ResponseMetadata = {}) {
    ModelUtils.assertIdentifierIsValid(identifier);
    this.identifier = identifier;
    this.apiResource = createLazyResource(() => fetchItem(identifier));

    this.preloadedMetadata = new Map(Object.entries(metadata));
  }
}

export class ItemParent extends ItemInterfaceParent {
  static async fromIdentifier(identifier: string): Promise<ItemParent | null> {
    // TODO: Confirm that this is not a collection
    return new ItemParent(identifier);
  }
}

export class CollectionParent extends ItemInterfaceParent {
  static async fromIdentifier(identifier: string): Promise<ItemParent | null> {
    // TODO: Confirm that this __is__ a collection
    return new CollectionParent(identifier);
  }
}

export class FileParent {
  itemIdentifier: string;
  json: ResponseFile;

  constructor(json: ResponseFile, itemIdentifier: string) {
    this.json = json;
    this.itemIdentifier = itemIdentifier;
  }
}

export class RichTextParent {
  html: string;
  constructor(html: string) {
    this.html = html;
  }
}
