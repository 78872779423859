export type LazyResource<T> = {
  read(): Promise<T>;
};

export function createLazyResource<T>(request: () => Promise<T>) {
  let promise: Promise<T> | null = null;
  return {
    read(): Promise<T> {
      if (promise == null) {
        promise = request();
      }
      return promise;
    }
  };
}
